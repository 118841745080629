import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';

import {
  LocationStrategy,
  PathLocationStrategy,
  registerLocaleData,
} from '@angular/common';
import en from '@angular/common/locales/en';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import {
  provideAuth,
  SharedModule,
  TemplateModule,
  ThemeConstantService,
} from '@forceget/base-theme';
import { NgChartjsModule } from 'ng-chartjs';

registerLocaleData(en);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NzBreadCrumbModule,
    TemplateModule,
    SharedModule,
    NgChartjsModule,
  ],
  providers: [
    provideAuth(),
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    ThemeConstantService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
