import { Component, inject } from '@angular/core';
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  private readonly router = inject(Router);

  isLoading = false;

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) this.isLoading = true;
      else if (event instanceof RouteConfigLoadEnd)
        setTimeout(() => (this.isLoading = false), 1000);
    });
  }
}
