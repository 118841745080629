import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  CommonLayoutComponent, Error1Component,
  FullLayoutComponent, Login1Component,
} from '@forceget/base-theme';
import { environment } from "../environments/environment";
const appRoutes: Routes = [
  // Redirect empty path to '/dashboards/project'
  { path: '', pathMatch: 'full', redirectTo: '' },
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: '' },
  { path: 'sign-in', pathMatch: 'full', redirectTo: 'account/login' },
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: CommonLayoutComponent,
    children: [
      //getstock
      {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(a => a.PagesModule),
        data: {
          apiUrl: environment.apiUrl
        }
      },
    ],
    data: {
      module: 'getstock',
      apiUrl: environment.apiUrl
    }
  },
  {
    path: 'account',
    component: FullLayoutComponent,
    data: {
      apiUrl: environment.apiUrl
    },
    children: [
      {
        path: 'login',
        component: Login1Component,
        data: {
          apiUrl: environment.apiUrl
        }
      }
    ]
  },
  { path: '*', component: Error1Component },
  { path: '**', component: Error1Component }
];
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}